import React from "react";

import Container from "../containers/container";
import ProductBrand from "../../components/productBrand";
import ContactForm from "../../components/contactForm";

const ContactContainer = ({notAligned = false}) => (
  <Container idSection='contatti' notAligned={notAligned} customClass={"e-containers__container--dark e-containers__container--form"}>
    <hr className='e-spacer py-8' />
    <div className='col-10 offset-1 col-lg-7 offset-lg-0'>
      <ContactForm customClass={""} />
    </div>
    <div className='col-10 offset-1 col-lg-5 mt-6 mt-lg-0 offset-lg-0'>
      <ProductBrand customClass={""} />
    </div>

    <hr className='e-spacer my-10 py-3' />
  </Container>
);

export default ContactContainer;
