import React, {Component} from "react";

class ProductBrand extends Component {
  render() {
    return (
      <div className={`c-product-brand ${this.props.customClass === undefined ? "" : this.props.customClass}`}>
        <h4 className='mb-8'>I migliori brand per i nostri prodotti</h4>
        <img src='/image/brand/Siemens.svg' alt={"Siemens"} />
        <img src='/image/brand/Panasonic.svg' alt={"Panasonic"} />
        <img src='/image/brand/CT-Logo.png' alt={"CT"} />
        <img src='/image/brand/Keb.png' alt={"Keb"} className='d-md-none' />
        <img src='/image/brand/kllmorgen.svg' alt={"kllmorgen"} className='d-md-none' />
        <img src='/image/brand/omron.svg' alt={"Omron"} className='d-md-none' />
        <img src='/image/brand/weintek.svg' alt={"Weintek"} className='d-md-none' />
        <img src='/image/brand/reer.svg' alt={"Reer"} className='d-md-none' />
        <img src='/image/brand/asem.svg' alt={"Asem"} className='d-md-none' />
      </div>
    );
  }
}

export default ProductBrand;
