import React from "react";

const Container = ({children, idSection = "", notAligned = false, customClass = "", customStyle = ""}) => (
  <div className={`e-containers__container ${notAligned === true ? "not-aligned" : ""} ${customClass}`} id={idSection}>
    <div className='container'>
      <div className='row'>{children}</div>
    </div>
  </div>
);

export default Container;
