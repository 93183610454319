import React from "react";
import Header from "./header/header";

const Herobanner = ({children, background, large = false}) => {
  const backgroundClass = !avaibleBackground.includes(background) ? "homepage" : background;
  console.log(background);
  return (
    <div className={`c-herobanner ${backgroundClass}`}>
      <Header />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className={`c-herobanner__content ${large ? "large" : ""}`}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const avaibleBackground = ["homepage", "progettazione", "quadri", "assistenza", "software", "retro", "chi-siamo", "contatti"];

export default Herobanner;
