const sendmail = async values => {
  const rawResponse = await fetch("/.netlify/functions/sendmail", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(values)
  });
  return rawResponse.ok;
};

export default sendmail;
