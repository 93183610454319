import React, {Component} from "react";
import * as Yup from "yup";
import {Formik, Form, Field} from "formik";

import Textbox from "./elements/form/textbox";
import Button from "./elements/button";
import Textarea from "./elements/form/textarea";
import Checkbox from "./elements/form/checkbox";
import sendEmail from "../utils/sendEmail";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {submitted: false, resultMessage: null};
  }

  render() {
    return (
      <div className={`c-contact-form ${this.props.customClass === undefined ? "" : this.props.customClass}`}>
        <h3 className='mb-6'>Richiedi informazioni</h3>
        <a className='e-button e-button--block' href='mailto:info@elettronicascarpa.it?subject=Elettronica Scarpa - Dicci cosa ti serve'>
          Clicca e scrivici
          <div className='e-button__arrow'></div>
          <div className='e-button__arrow-background'></div>
        </a>
        {/* <Formik
          initialValues={{nome: "", email: "", telefono: "", servizio: "", messaggio: "", privacy: false}}
          onSubmit={async values => await this.onSubmit(values)}
          validationSchema={Yup.object().shape({
            nome: Yup.string().required(),
            email: Yup.string()
              .email()
              .required(),
            telefono: Yup.string().required(),
            servizio: Yup.string().required(),
            messaggio: Yup.string().required(),
            privacy: Yup.bool().oneOf([true], "La privacy deve essere accettata")
          })}
        >
          {form => {
            return (
              <Form>
                <div className='row'>
                  <div className='col-12 col-lg-6 mb-3'>
                    <Textbox placeholder='Nome' aria-label='Nome' name='nome' disabled={this.state.submitted} />
                  </div>
                  <div className='col-12 col-lg-6 mb-3'>
                    <Textbox placeholder='Email' aria-label='Email' name='email' disabled={this.state.submitted} />
                  </div>
                  <div className='col-12 col-lg-6 mb-3'>
                    <Textbox placeholder='Telefono' aria-label='Telefono' name='telefono' disabled={this.state.submitted} />
                  </div>
                  <div className='col-12 col-lg-6 mb-3'>
                    <Textbox placeholder='Servizio' aria-label='Servizio' name='servizio' disabled={this.state.submitted} />
                  </div>
                  <div className='col-12 mb-2 mb-lg-4'>
                    <Textarea placeholder='Dicci cosa ti serve' aria-label='Messaggio' name='messaggio' rows={5} disabled={this.state.submitted} />
                  </div>
                  <div className='align-self-center col-12 col-lg-6 mb-4'>
                    <Field
                      name={"privacy"}
                      as={Checkbox}
                      label={"Accetto i temini della <a href='/privacy' title='Privacy Policy | Elettronica Scarpa'>Privacy Policy</a>"}
                      disabled={this.state.submitted}
                    />
                  </div>
                  <div className='col-12 col-lg-6'>
                    <Button customClass={"e-button--block e-button--flex"} isButton={true} type='submit' disabled={this.state.submitted}>
                      Richiedi un contatto
                    </Button>
                  </div>
                  {this.state.resultMessage !== null && (
                    <div className={`col-12 mt-1 c-contact-form__result-message ${this.state.resultMessage.success === true ? "" : "error"}`}>{this.state.resultMessage.message}</div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik> */}
      </div>
    );
  }

  onSubmit = async values => {
    const success = await sendEmail(values);
    this.setState({submitted: true, resultMessage: success === true ? {success: success, message: "Grazie per averci contatti"} : {success: success, message: "Si è verificato un errore"}});
  };
}

export default ContactForm;
