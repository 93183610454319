import React, {Component} from "react";
import {useField} from "formik";

const Checkbox = ({...props}) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);
  return (
    <div className={"e-input__checkbox"}>
      <CheckboxSquare error={meta.error && meta.touched} onChange={helpers.setValue} disabled={props.disabled} />
      <span dangerouslySetInnerHTML={{__html: props.label}} />
      <input {...field} {...props} type='hidden' />
    </div>
  );
};

class CheckboxSquare extends Component {
  constructor(props) {
    super(props);
    this.state = {active: false};
  }

  render() {
    const {error} = this.props;
    return (
      <div
        className={`e-input__checkbox-box ${error ? "error" : ""}`}
        onClick={this.toggleValue}
        onKeyDown={this.toggleValue}
        role='checkbox'
        aria-checked='true'
        tabIndex={0}
        aria-label='Menù dei servizi'
      >
        <div className={`e-input__checkbox-box-check ${this.state.active ? "checked" : ""}`} />
      </div>
    );
  }

  toggleValue = () => {
    if (this.props.disabled !== true) {
      this.props.onChange(!this.state.active);
      this.setState({active: !this.state.active});
    }
  };
}

export default Checkbox;
