import React from "react";

const IconMiniTitle = ({children}) => (
  <div className='c-mini-title'>
    <div className='c-mini-title__icon' />
    {children}
  </div>
);

export default IconMiniTitle;
