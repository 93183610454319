import React from "react";
import {Link} from "gatsby";

const Button = ({children, to = "", customClass = "", isButton = false, ...props}) => {
  return !isButton ? (
    <Link to={to} className={`e-button ${customClass}`} {...props}>
      {children}
      <div className='e-button__arrow' />
      <div className='e-button__arrow-background' />
    </Link>
  ) : (
    <button className={`e-button ${customClass}`} {...props}>
      {children}
      <div className='e-button__arrow' />
      <div className='e-button__arrow-background' />
    </button>
  );
};

export default Button;
